import React, { useState, useEffect } from 'react';
import { tokenContract } from './Common';

function Header() {
  const [isClassToggled, setIsClassToggled] = useState(false);
  const [balance, setBalance] = useState();
  const [isLocked, setIsLocked] = useState(false)

  const handleClick = () => {
    setIsClassToggled(prevState => !prevState);
  };

  const tokenBalance = async () => {
    try {
      const info = await tokenContract.methods.balanceOf(sessionStorage.getItem("account")).call();
      const tokenBal = Number(info) / (10 ** 18);
      if (info > 0) {
        const formattedBalance = parseFloat(tokenBal.toString()).toFixed(2);
        setBalance(formattedBalance);
      } else {
        setBalance('0.00');
      }
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  useEffect(() => {
    const updateTokenBalance = async () => {
      if (sessionStorage.getItem("account") !== null) {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        // const isLocked = await window.ethereum._metamask.isUnlocked();
        const isLocked = accounts.length === 0;
        if (!isLocked) {
          setIsLocked(true)
          await tokenBalance();
        } else {
          setIsLocked(false)
        }
      } else {
        setIsLocked(false)
      }
    };

    // Call the updateTokenBalance function immediately
    updateTokenBalance();

    // Set up an interval to periodically update the token balance (e.g., every 10 seconds)
    const interval = setInterval(updateTokenBalance, 1000); // Adjust the interval time as needed
    return () => clearInterval(interval);
  }, []);


  return (
    <>
      <div className="main-header">
        <div className="container d-flex justify-content-between align-items-center h-100">
          {/* <div className="logo">LOGO</div> */}
          <img src={process.env.PUBLIC_URL + "/media/logo1.png"} className="img-fluid logo h-50px" />
          <div className="menu d-none d-md-block">
            <ul>
              <li><a href="#home">Home</a></li>
              <li><a href="#token-info">Token-info</a></li>
              <li><a href="#tor-development">project-ARA</a></li>
              <li><a href="#home">Generate-ARA</a></li>
              <li><a href="#exchanges">Exchange</a></li>
              {sessionStorage.getItem("account") !== null && isLocked ? <li><a href="#" className="section-link"><span className='bal'>{balance} ARA</span></a></li> : ''}

            </ul>
          </div>
          <i onClick={handleClick} className="fa fa-bars fs-1 text-primary d-block d-md-none" aria-hidden="true"></i>
        </div>
      </div>

      {/* ==================== Mobile menu ==================== */}
      <div className={`mobile-menu ${isClassToggled ? 'mobile-menu-visible' : ''}`}>
        <div className="close-btn" onClick={handleClick}><i className="fa fa-times"></i></div>
        <div className="nav-logo h-150px"><a href="/#" className="h-100"><img src={process.env.PUBLIC_URL + "/media/logo.png"} alt="" title="" className="h-100" /></a></div>
        <div className="menu-outer">
          <ul className="navigation">
            <li><a href="#home" className="section-link">Home</a></li>
            <li><a href="#token-info" className="section-link">Token-info</a></li>
            <li><a href="#tor-development" className="section-link">project-ARA</a></li>
            <li><a href="#home" className="section-link">Generate-ARA</a></li>
            <li><a href="#exchanges" className="section-link">Exchange</a></li>
            {sessionStorage.getItem("account") !== null ? <li><a href="#" className="section-link"><span className='bal1'>{balance} ARA</span></a></li> : ''}

          </ul>
        </div>
      </div>
    </>
  );
}

export default Header;