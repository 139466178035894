import React, { Component } from 'react';

function Footer() {
        return (
            <div className="footer bg-dark">
        <div className="container pt-0 py-10">
          <div className="tor-bg row shadow">
            <div className="col-lg-8 col-md-12 d-flex gap-4 flex-lg-nowrap flex-wrap">
                <img src={process.env.PUBLIC_URL+ "/media/logo.png"} className="h-100px"/>
                <div className="my-8 my-lg-0">
                  <h1 className="text-primary">ARA</h1>
                  <h4 className="text-primary my-3">Innovating for Good</h4>
                  <p className="text-primary">Building blockchain, commerce, metaverse and NFT products to derive new kinds of value ii.  from crypto technology and to apply it to increasingly better use. Advancing our innovations to every part of the world. </p>
                  <ul className="social-icon p-0">
                    <li><a target="_blank" href=""><i class="fa fa-simplybuilt" aria-hidden="true"></i></a></li>
                    <li><a target="_blank" href="https://www.facebook.com"><i class="fa fa-facebook-square" aria-hidden="true"></i></a></li>
                    <li><a target="_blank" href=""><i class="fa fa-twitter-square" aria-hidden="true"></i></a></li>
                    <li><a target="_blank" href="https://www.instagram.com"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                    <li><a target="_blank" href=""><i class="fa fa-linkedin-square" aria-hidden="true"></i></a></li>
                    <li><a target="_blank" href="https://www.youtube.com/"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                    <li><a target="_blank" href=""><i class="fa fa-reddit" aria-hidden="true"></i></a></li>
                  </ul>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 my-8 my-lg-0">
              <h3 className="text-primary">support@aracoin.app </h3>
              {/* <h3 className="text-white">Generator </h3> */}
              {/* <h3 className="text-primary my-5 mb-0">Generator  </h3> */}
            </div>
            </div>
        </div>
      </div>
        );
}

export default Footer;