import React, { useState } from 'react';

function TorDeveloper() {

    return (
        <div className="" id="tor-development">
        <div className="container">
          <div className="tor-bg">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 px-4 px-xl-20 px-lg-10 p-5 position-relative d-flex justify-content-center align-items-center overflow-hidden">
                <img src={process.env.PUBLIC_URL+ "/media/tor-circle.png"} className="img-fluid spin"/>
                <img src={process.env.PUBLIC_URL+ "/media/logo.png"} className="img-fluid position-absolute" style={{width: "24%"}}/>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12 d-flex flex-column justify-content-center">
                <h1 className="text-primary my-5 mt-0">ARA DEVELOPMENTS </h1>
                <h2 className="text-primary my-6 mt-0">MISSION </h2>
                <p className="text-primary my-5 mt-0">To build a decentralized trading platform that meets the actual needs of the enterprise to support efficient digital asset management, trading and lightning payment services. ARA aims to keep the user to same ease of use and speed as a centralized exchange. Efficient, secure, and fully user-controlled, with the processing power and user experience of the current centralized system.</p>
              </div>
            </div>
            </div>
      {/*  ============================ VISION ========================*/}
          <div className="tor-bg my-16">
            <h2 className="text-primary my-6 mt-0">VISION</h2>
            <p className="text-primary my-5 mt-0">The ARA platform is an open-source, P2P protocol built on the Fast Access Blockchain. Individuals and enterprises can trade or transact on ARA with BEP20 tokens and all other types of cryptocurrencies. Transactions arefacilitatedthroughahigh-speedtradingengine that canprocesspayment orredemptiontransaction at thespeed of morethanone million per second. The trading engine relies on aggregated multi-signatures, sharding and cross-chains to provide real-time messaging, clearing and settlement of transactions.

              Designed with the needs of the international crypto trader, wealth manager and enterprise, it has a fully decentralized digital asset management network, trading platform and global payments system. ARA plans to solve the inherent problems that exist with centralized exchanges, namely, scalability, interoperability, low transaction processing, security vulnerabilities and guarantees for transaction processing are prevalent issues. Traders retain control over their funds, access to private keys and transaction information is transparent and secure. It is a fully democratic and autonomous platform. Any team or individual has no privilege, the benefits are shared, and the decision making and voting right are held by ARA (ARA).

              Shortly, various cryptocurrencies will be used as a means of payment, which can be easily traded tomeetthedaily needs at bothenterpriseandindividuallevel. Therefore, establishingahigh- performance decentralized transaction processing system has long-term implications.
              </p>
            </div>
        </div>
      </div>
    );
  }
  
  export default TorDeveloper;  