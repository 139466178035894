import React, { useState } from 'react';
import { ContractAddress } from './Common';

function ProjectTor() {

  return (
    <div className="projector-tor" id='token-info'>
      <div className="container">
        <div className="tor-bg">
          <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-12 position-relative d-flex flex-column justify-content-center">
              <h1 className="text-primary mb-4">PROJECT ARA</h1>
              <p className="text-primary"><b>ARA</b> is a type of decentralized cryptocurrency exchange. Decentralized cryptocurrency exchanges are a new generation of peer-to-peer (P2P) platforms that will be more transparent in operations and fees than the current exchange model. Its a new decentralized model based on the Fast Access Blockchain. It carries the best of a centralized exchange, namely speed and easy use with all the security benefits of a decentralized exchange.</p>

              <h1 className="text-primary mb-4 my-12">TOKEN INFORMATION</h1>
              <h3 className="text-primary">Ticker: ARA</h3>
              <h3 className="text-primary">Name: ARACOIN</h3>
              <h3 className="text-primary text-break">Max. supply: 1,000,000,000,000,000 ARA</h3>
              <h3 className="text-primary">Network: BNB Smart Chain (BSC) </h3>
              <h3 className="text-primary text-break">Contract: {ContractAddress}</h3>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 offset-lg-1 d-flex justify-content-center">
              <img src={process.env.PUBLIC_URL + "/media/projector-tor.jpg"} className="img-fluid " />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectTor;