import React, { useState } from 'react';

function MeetOurPartner() {

    return (
        <div className="branding">
        <div className="container">
          <div className="tor-bg">
            <h1 className="text-primary text-center my-6 mt-0">Meet Our Partner </h1>
            <div class="partner-wrap">
              <ul>
                <li><a href=""><img src={process.env.PUBLIC_URL+ "/media/p1.png"}/></a></li>
                <li><a href=""><img src={process.env.PUBLIC_URL+ "/media/p2.png"}/></a></li>
                <li><a href=""><img src={process.env.PUBLIC_URL+ "/media/p3.png"}/></a></li>
                <li><a href=""><img src={process.env.PUBLIC_URL+ "/media/p4.png"}/></a></li>
                <li><a href=""><img src={process.env.PUBLIC_URL+ "/media/p5.png"}/></a></li>
                <li><a href=""><img src={process.env.PUBLIC_URL+ "/media/p6.png"}/></a></li>
                <li><a href=""><img src={process.env.PUBLIC_URL+ "/media/p7.png"}/></a></li>
                <li><a href=""><img src={process.env.PUBLIC_URL+ "/media/p8.png"}/></a></li>
                <li><a href=""><img src={process.env.PUBLIC_URL+ "/media/p11.png"}/></a></li>
                <li><a href=""><img src={process.env.PUBLIC_URL+ "/media/p10.png"}/></a></li>
              </ul>
            </div >
            
            </div>
        </div>
      </div>
    );
  }
  
  export default MeetOurPartner;