import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Home from './component/Home';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      <BrowserRouter>
        <div className="main">
          {isLoading ? (
            <div className="tor-loader">
              <img src={process.env.PUBLIC_URL + "/media/loader.gif"} alt='loader' />
            </div>
          ) : (
            <>
              <img className="background_image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/media/bg6.jpg)` }} />
              <div className='z-index-3 position-relative'>
                <Home />
              </div>
            </>
          )}
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
