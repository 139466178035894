import Footer from "./Footer";
import Header from "./Header";
import Hero from "./Hero";
import MeetOurPartner from "./MeetOurPartner";
import ProjectTor from "./ProjectTor";
import TorDeveloper from "./TorDeveloper";

function Home() {
    return (
      <>
      <Header />
      <Hero />
      {/*  ============================ PROJECT TOR ========================*/}
      <ProjectTor />
      {/*  ============================ Branding ========================*/}
      <MeetOurPartner />
      {/*  ============================ TOR DEVELOPMENTS ========================*/}
      <TorDeveloper />
      {/*  ============================ footer ========================*/}
      <Footer />
      </>
    );
  }
  
  export default Home;