import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import { ActiveNetwork, ContractAddress, RPCURL, mintAmount, tokenPrice, ContractAbi, exchangeInfo, web3 } from "./Common";
import Web3 from 'web3';


function Hero() {
  const [items, setItem] = useState([]);
  const [showItems, setShowItems] = useState(10);
  const [showAll, setShowAll] = useState(false);
  const [isClassToggled, setIsClassToggled] = useState(false);
  const [account, setAccount] = useState(null);
  const [mintAmt, setAmount] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [price, setPrice] = useState(0)
  let [count, setCount] = useState(0);
  const [intervalStauts, setIntervalStatus] = useState(null);
  const [buttonStatus, setButtonStatus] = useState(false);
  const handleClick = () => {
    if (showAll) {
      setShowItems(10);
      setShowAll(false);
    } else {
      setShowItems((prevShowItems) => prevShowItems + 10);
      if (showItems + 10 >= items.length) {
        setShowAll(true);
      }
    }
  };

  const popupFun = () => {
    setIsClassToggled(prevState => !prevState);
  };
  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const tokens = await exchangeInfo();
        if (tokens.success) {
          setItem(tokens.data);
        }
      } catch (err) {
        console.error('Error fetching tokens:', err);
      }
    };
    fetchTokens();

  })

  async function timerFunction(address) {
    const tokenContract = new web3.eth.Contract(ContractAbi, ContractAddress);
    let lastMintTime = await tokenContract.methods.lastMintTime(address).call();
    let mintTime = await tokenContract.methods.mintTime().call();
    let time = Number(lastMintTime) + Number(mintTime);
    clearInterval(intervalStauts);
    const myInterval = setInterval(() => {
      const now = Math.floor((Date.now()) / 1000);
      let distance = time - now;
      if (Number(distance) > 0) {
        setCount(distance);
      } else {
        setCount(0);
        clearInterval(myInterval);
      }
    }, 1000);
    setIntervalStatus(myInterval);
  }

  useEffect(() => {
    setTimeout(async () => {
      if (account != "" && account != null) {
        await timerFunction(account);
      }
    }, 1000);
  }, [account]);

  const connectWallet = async () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' }) // Request access to the connected accounts
        .then(async (accounts) => {
          await swichNetworkHandler();
          setAccount(accounts[0]);
          // await timerFunction(accounts[0]);
          sessionStorage.setItem("account", accounts[0]);
          sessionStorage.setItem("isLoked", true);

          // Add custom token to MetaMask
          window.ethereum
            .request({
              method: 'wallet_watchAsset',
              params: {
                type: 'ERC20',
                options: {
                  address: ContractAddress,
                  symbol: 'ARA',
                  decimals: 18,
                },
              },
            })
            .then((success) => {
              if (success) {
                Swal.fire({ title: "<h5 style='color:white'>Custom token added to MetaMask!</h5>", icon: "success", background: "#808080", showConfirmButton: false, timer: 4000 });
              } else {
                Swal.fire({ title: "<h5 style='color:white'>Error adding custom token to MetaMaskk!</h5>", icon: "error", background: "#808080", showConfirmButton: false, timer: 4000 });
              }
            })
            .catch((error) => {
              Swal.fire({ title: "<h5 style='color:white'>Error adding custom token to MetaMaskk!</h5>", icon: "error", background: "#808080", showConfirmButton: false, timer: 4000 });
            });
        })
        .catch((error) => {
          Swal.fire({ title: "<h5 style='color:white'>Error retrieving connected wallet address!</h5>", icon: "warning", background: "#808080", showConfirmButton: false, timer: 4000 });
        });
    } else {
      Swal.fire({ title: "<h5 style='color:white'>Please Install Metamask wallet!</h5>", icon: "warning", background: "#808080", showConfirmButton: false, timer: 4000 });
    }
  }

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ActiveNetwork.toString(16) }],
      });
    } catch (error) {
      if (error.code === 4902) {
        Swal.fire({ title: "<h5 style='color:white'>Please add the sepolia network in your metamask wallet.</h5>", icon: "warning", background: "#808080", showConfirmButton: false, timer: 4000 });
        setAccount('');
      }
    }
  }

  const generateTokens = async () => {
    // try {
    if (account != null && account != "" && account != undefined) {
      setIsClicked(true);
      const web3 = new Web3(window.ethereum || RPCURL);
      const tokenContract = new web3.eth.Contract(ContractAbi, ContractAddress);
      let lastMintTime = await tokenContract.methods.lastMintTime(account).call();
      let mintTime = await tokenContract.methods.mintTime().call();
      if (Math.floor(Date.now() / 1000) > Number(lastMintTime) + Number(mintTime)) {
        let payAmount = await tokenContract.methods.bnbHelper().call();
        payAmount = Math.round(payAmount * 1.5);
        let gasPrice = await web3.eth.getGasPrice();

        try {
          tokenContract.methods.mint().estimateGas({ from: account, value: payAmount.toString() })
            .then(async (gasEstimate) => {
              console.log("Gas estimate:", gasEstimate);
              // Handle the gas estimate if needed
              //--------------------------------------------------------------------------------------------
              let balance = Number(await web3.eth.getBalance(account.toString()));
              if (balance <= Number(gasPrice)) return Swal.fire({ title: "<h5 style='color:white'>Insufficient your wallet balance.</h5>", icon: "error", background: "#808080", showConfirmButton: true, timer: 4000 });
              let estimateGas = await tokenContract.methods.mint().estimateGas({ from: account, value: payAmount.toString() });
              let paymentedAmount = (estimateGas * gasPrice + Number(payAmount));
              if (Number(balance) <= Number(paymentedAmount)) return Swal.fire({ title: "<h5 style='color:white'>Insufficient BNB balance for this transaction!</h5>", icon: "error", background: "#808080", showConfirmButton: false, timer: 4000 });
              setButtonStatus(true);
              tokenContract.methods.mint().send({ from: account, value: payAmount.toString(), gasPrice, gas: estimateGas }).on('transactionHash', async function (hash) {
                Swal.fire({
                  title: "<h5 style='color:white'>Your transaction is sent, please wait for confirmation!</h5>",
                  icon: "success",
                  background: "#808080",
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  html: `
                    <div className='progress-bar-loader'>
                      <div className='progress-bar-line'></div>
                    </div>
                    `,
                });

                // Update the progress bar loader width based on the transaction confirmation
                let loaderElement = Swal.getPopup().querySelector('.progress-bar-line');
                let interval1 = setInterval(async () => {
                  const receipt = await web3.eth.getTransactionReceipt(hash);
                  if (receipt && receipt.status) {
                    await timerFunction(account);
                    const newTokenPrice = await tokenPrice();
                    setPrice(newTokenPrice);
                    clearInterval(interval1);
                    Swal.close(); // Close the Swal dialog when the transaction is confirmed
                    Swal.fire({ title: "<h5 style='color:white'>Tokens generated successfully!</h5>", icon: "success", background: "#808080", timer: 4000 });
                  } else if (receipt && !receipt.status) {
                    clearInterval(interval1);
                    Swal.close();
                    Swal.fire({ title: "<h5 style='color:white'>Transaction Failed!</h5>", icon: "error", background: "#808080", timer: 4000 });
                  } else {
                    const latestBlock = await web3.eth.getBlockNumber();
                    const currentBlock = receipt ? receipt.blockNumber : latestBlock;
                    const totalBlocks = latestBlock - Number(lastMintTime) + Number(mintTime);
                    const progress = ((currentBlock - Number(lastMintTime)) / totalBlocks) * 100;
                    loaderElement.style.width = `${progress}%`;
                    loaderElement.style.backgroundColor = 'red'; // Set the color to red
                    if (progress < 100) {
                      loaderElement.style.backgroundColor = 'red';
                    }
                  }
                }, 2000);
                setButtonStatus(false);
              }).catch((error) => {
                setButtonStatus(false);
                if (error.code == 4001) {
                  Swal.fire({ title: "<h5 style='color:white'>MetaMask Tx Signature: You denied transaction signature.</h5>", icon: "error", background: "#808080", showConfirmButton: true, timer: 4000 });
                }
              })
              //---------------------------------------------------------------------------------------
            })
            .catch(error => {
              console.error("Error estimating gas:", error);
              return Swal.fire({ title: "<h5 style='color:white'>Insufficient your wallet balance.</h5>", icon: "error", background: "#808080", showConfirmButton: true, timer: 4000 });
              // Handle the error here
            });
        } catch (error) {
          console.error("Error estimating gas:", error);
          return Swal.fire({ title: "<h5 style='color:white'>Insufficient your wallet balance.</h5>", icon: "error", background: "#808080", showConfirmButton: true, timer: 4000 });
        }

      } else {
        Swal.fire({ title: "<h5 style='color:white'>Mint tokens after some time!</h5>", icon: "warning", background: "#808080", showConfirmButton: false, timer: 4000 });
      }

    } else {
      Swal.fire({ title: "<h5 style='color:white'>Connect your wallet!</h5>", icon: "warning", background: "#808080", showConfirmButton: false, timer: 4000 });
    }
    // } catch (error) {
    //   console.log(error.message, " errorerrorerror")
    //   // Swal.fire({ title: "<h5 style='color:white'>Something went wrong, please refresh the page. and try again!</h5>", icon: "error", background: "#808080", showConfirmButton: false, timer: 4000 });
    // }
    setIsClicked(false);
  };

  const disconnectWallet = () => {
    sessionStorage.removeItem("account", account);
    sessionStorage.setItem("isLoked", false);
    setAccount('');
    setIsLocked(false);
  }

  useEffect(() => {
    if (sessionStorage.getItem('account') !== null) {
      setAccount(sessionStorage.getItem('account'));
    }


  }, []); //fetchTokens, fetchLivePrice


  useEffect(() => {
    const checkMetamask = async () => {
      // const isLocked = await window.ethereum._metamask.isUnlocked();
      const isLocked = sessionStorage.getItem("isLoked");
      if (isLocked) {
        setIsLocked(true)
        setAccount(sessionStorage.getItem("account"));
      } else {
        setIsLocked(false)
        sessionStorage.removeItem("account", account);
        setAccount('');
      }
    };

    // Call the updateTokenBalance function immediately
    checkMetamask();

    // Set up an interval to periodically update the token balance (e.g., every 10 seconds)
    const interval = setInterval(checkMetamask, 1000); // Adjust the interval time as needed
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const mintAmt = async () => {
      const amount = await mintAmount();
      const amt = Number(amount) / 10 ** 18;
      setAmount(amt.toString());
      const newTokenPrice = await tokenPrice();
      setPrice(newTokenPrice);
    }
    mintAmt()

  }, [mintAmount]);

  useEffect(() => {
    if (account) {
      setIsClassToggled(false)
    }
  }, [account]);

  useEffect(() => {
    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        setIsLocked(false)
        sessionStorage.removeItem("account", account);
        setAccount('');
      } else {
        setAccount(accounts[0]);
        sessionStorage.setItem("account", accounts[0]);
        sessionStorage.setItem("isLoked", true);
      }
    };
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }

    return () => {
      window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
    };
  }, []);

  return (
    <>
      <div className="hero" id="home">
        <div className="container">
          <div className="tor-bg">
            <div className="row">
              <div className="col-xl-4 px-md-5 px-0 col-lg-4 col-md-6 position-relative d-flex justify-content-center align-items-center overflow-hidden">
                <img src={process.env.PUBLIC_URL + "/media/circle.png"} className="img-fluid spin circle-img" />
                <div className="d-flex flex-column justify-content-center gap-5 position-absolute">
                  <a className="btn btn-dark px-9"> {mintAmt} ARA PER Day.</a>
                  {count > 0 && account ?
                    <button className="btn btn-dark fw-700 text-white">
                      {/* <span className="timer1">{Math.floor(count / (60 * 60 * 24)) + ":"} */}
                      <span className="timer1">{Math.floor((count % (60 * 60 * 24)) / (60 * 60)) + ":"}
                        <span className="timer1">{Math.floor((count % (60 * 60)) / (60)) + ":"}
                          <span className="timer1">{Math.floor((count % (60)))}
                          </span>
                        </span>
                      </span>
                      {/* </span> */}
                    </button> :
                    <button disabled={buttonStatus} className="btn btn-danger fw-700 text-white" style={{ backgroundColor: isClicked ? 'black' : '', color: isClicked ? 'white' : '' }}
                      onClick={generateTokens}>Generate ARA</button>
                  }

                  {account && isLocked ? <button className="btn btn-dark boldButton" onClick={disconnectWallet}>Disconnect</button> : <button className="btn btn-dark boldButton" onClick={popupFun} >Connect</button>}
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 offset-lg-1 col-md-6 col-sm-12 d-flex justify-content-center align-items-center flex-column gap-4" id="exchanges">
                <button className="btn btn-danger PX-10 fw-600" style={{ color: 'white' }}>1 ARA = {price} $</button>
                <div className="exchanges">
                  <div className="d-flex justify-content-center ">
                    <button className="btn btn-dark mb-4 rounded-0">Exchanges</button>
                  </div>

                  <div className="row">
                    {items.slice(0, showItems).map((item, index) => (

                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12" key={item.id}>
                        <p><a href={item.link} className='text-black fw-600 text-hover-danger' target="_blank">{`${index + 1}.${item.name}`}</a></p>
                      </div>
                    ))}
                  </div>
                  <div className="d-flex justify-content-center mt-5" id='project-tor'>
                    {items.length > 10 && (
                      <button onClick={handleClick} className="btn btn-dark w-150px">
                        {showAll ? 'View Less' : 'View More'}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal-wrapper ${isClassToggled ? 'd-flex' : 'd-none'}`} >
        <div className='modal-content'>
          <span className="close" onClick={popupFun}>×</span>
          <div className='d-flex gap-4 justify-content-center align-items-center h-100'>
            <img src={process.env.PUBLIC_URL + "/media/logo-black.png"} alt='loader' className='w-80px w-sm-100px' />
            <div className='metamask-button'>
              <img src={process.env.PUBLIC_URL + "/media/metamask.png"} alt='loader' className='w-40px' />
              <span onClick={connectWallet}>connect metamask</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;